<template>
  <div>
    <el-popover placement="right" :width="width" trigger="hover">
      <el-table
        v-show="type == 'disc_class'"
        v-loading="extraLoading"
        :data="cardExtraList"
      >
        <el-table-column prop="ClassNo" label="分类编号"></el-table-column>
        <el-table-column prop="ClassName" label="分类名称"></el-table-column>
        <el-table-column label="折扣模式">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.ClassDiscModel
                  | valueAndNameChange(cardSettingList.discMode)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="按金额">
          <template slot-scope="scope">
            <div>{{ scope.row.ClassDiscMoney || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="按比例">
          <template slot-scope="scope">
            <div>{{ scope.row.ClassDiscName || "无" }}</div>
          </template>
        </el-table-column>
      </el-table>

      <el-table
        v-show="type == 'disc_item'"
        v-loading="extraLoading"
        :data="cardExtraList"
      >
        <el-table-column
          prop="ClassName"
          label="项目分类"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="ItemNo"
          label="项目编号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="ItemName"
          label="项目名称"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="ItemPrice"
          label="项目单价"
          width="80"
        ></el-table-column>
        <el-table-column label="折扣模式">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.ItemDiscModel
                  | valueAndNameChange(cardSettingList.discMode)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="按金额">
          <template slot-scope="scope">
            <div>{{ scope.row.ItemDiscMoney || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="按比例">
          <template slot-scope="scope">
            <div>{{ scope.row.ItemDiscName || "无" }}</div>
          </template>
        </el-table-column>
      </el-table>

      <el-table
        v-show="type == 'pack_item'"
        v-loading="extraLoading"
        :data="cardExtraList"
      >
        <el-table-column prop="ItemNo" label="编号"></el-table-column>
        <el-table-column prop="ItemName" label="项目名称"></el-table-column>
        <el-table-column label="单价">
          <template slot-scope="scope">
            <div>
              {{ scope.row.ItemPrice }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="组别">
          <template slot-scope="scope">
            <div>{{ scope.row.UniteIndex || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="可用次数">
          <template slot-scope="scope">
            <div>{{ scope.row.PackCount || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="不限次数">
          <template slot-scope="scope">
            <div>{{ scope.row.InfiniteMark || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="消耗额">
          <template slot-scope="scope">
            <div>{{ scope.row.Consume || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="有效期限">
          <template slot-scope="scope">
            <div>{{ scope.row.DateLimit || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="期限单位">
          <template slot-scope="scope">
            <div>{{ scope.row.DateUnits || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <div>{{ scope.row.Note || "无" }}</div>
          </template>
        </el-table-column>
      </el-table>

      <div
        class="popover-disc-item"
        slot="reference"
        style="color: #20a0ff"
        @mouseenter="initPageData"
      >
        <span>更多</span>
        <i class="el-icon-caret-bottom"></i>
      </div>
    </el-popover>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import card from "@/api/card";

export default {
  props: {
    cardSettingList: {
      type: Object,
      default: () => ({}),
    },

    width: {
      type: String,
      default: () => "400",
    },

    cardGuid: {
      type: String,
      default: () => "",
    },

    type: {
      type: String,
      default: () => "",
    },
  },

  data() {
    return {
      cardExtraList: [],
      extraLoading: true,
    };
  },

  filters: {
    valueAndNameChange(item, sourceList, keyName, valueName) {
      return utils.valueAndNameChange(item, sourceList, keyName, valueName);
    },
  },

  mounted() {},

  methods: {
    async initPageData() {
      let submitData = {
        card_guid: this.cardGuid,
        type: this.type,
      };
      this.extraLoading = true;

      let { data } = await card.getCardExtra(submitData);
      this.cardExtraList = data;
      // console.log(data);
      this.extraLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.popover-disc-item {
  display: flex;
  align-items: center;
  height: 40px;
}
</style>