<template>
  <div>
    <el-dialog
      title="选择同步卡类型相关对象"
      :visible.sync="showDialog"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-row :gutter="rowSpan">
          <el-col
            :span="rowSpan / 2"
            v-for="(item, index) in refsTypies"
            :key="index"
          >
            <el-checkbox
              v-model="item.Checked"
              :true-value="1"
              :false-value="0"
              >{{ item.Name }}</el-checkbox
            >
          </el-col>
        </el-row>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/api/card.js";

export default {
  data() {
    return {
      rowSpan: 22,
      refsTypies: [],
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 初始化卡类型相关对象
    async initCardAndRefs() {
      try {
        let { data } = await Card.initCardAndRefs();
        data.refsTypies.forEach((item) => {
          item.Checked = 0;
        });
        this.refsTypies = data.refsTypies;
      } catch (err) {
        console.log(err);
      }
    },

    onSubmit() {
      let { refsTypies } = this,
        arr = [];
      refsTypies.forEach((item) => {
        item.Checked ? arr.push(item.Value) : "";
      });
      if (!arr.length) {
        return this.$message.error("请选择至少一个相关对象");
      }
      this.$emit("success", arr);
      this.onCloseDialog();
    },

    // 打开对话框
    onShowDialog() {
      this.initCardAndRefs();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-row {
    .el-col {
      margin-block: 5px;
    }
  }
}
</style>
