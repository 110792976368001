<template>
  <div>
    <div class="pack-item-body">
      <!-- start 顶部tab栏 -->
      <div class="pack-top">
        <!-- 编辑模式按钮 -->
        <el-button type="primary" size="small " @click="editMode = !editMode">{{
          editMode ? "关闭编辑" : "开启编辑"
        }}</el-button>

        <!-- 平分消耗额按钮 -->
        <el-button
          v-show="editMode && type == 'pack_item'"
          size="small"
          :disabled="devideDisable"
          @click="devideConsumption"
          >平分消耗额</el-button
        >

        <!-- 套卡项目的选择项目按钮 -->
        <!-- <el-button
          v-show="
            editMode &&
            cardSettingList.cardInfo.NullPack != 0 &&
            type == 'pack_item'
          "
          size="small"
          @click="openSelectItemDialog"
          >选择项目</el-button
        > -->

        <!-- 赠送项目的选择项目按钮 -->
        <!-- <el-button
          v-show="
            editMode &&
            cardSettingList.cardInfo.DonaItem != 0 &&
            type == 'dona_item'
          "
          size="small"
          @click="openSelectItemDialog"
          >选择项目</el-button
        > -->

        <el-button v-show="editMode" size="small" @click="openSelectItemDialog"
          >选择项目</el-button
        >
      </div>
      <!-- end 顶部tab栏 -->

      <!-- start 套卡项目table -->
      <el-table
        v-show="type == 'pack_item'"
        v-loading="loading"
        :data="tableData"
        height="600"
      >
        <el-table-column label="编号" prop="ItemNo"> </el-table-column>
        <el-table-column label="项目名称" prop="ItemName"> </el-table-column>
        <el-table-column label="单价" prop="ItemPrice"> </el-table-column>
        <el-table-column label="组别">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-select
                size="small"
                v-model="scope.row.UniteIndex"
                @change="onSelectChange($event, scope.$index)"
                placeholder=""
              >
                <el-option
                  v-for="item in cardSettingList.uniteIndexList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-show="!editMode">
              {{
                scope.row.UniteIndex
                  | valueAndNameChange(cardSettingList.uniteIndexList)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="可用次数">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-input
                v-model="scope.row.PackCount"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-show="!editMode">{{ scope.row.PackCount || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="不限次数">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-select
                size="small"
                v-model="scope.row.InfiniteMark"
                @change="onSelectChange($event, scope.$index)"
                placeholder=""
              >
                <el-option
                  v-for="item in cardSettingList.booleanList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-show="!editMode">
              {{
                scope.row.InfiniteMark
                  | valueAndNameChange(cardSettingList.booleanList)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="消耗额">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-input
                v-model="scope.row.Consume"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-show="!editMode">{{ scope.row.Consume || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="有效期限">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-input
                v-model="scope.row.DateLimit"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-show="!editMode">{{ scope.row.DateLimit || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="期限单位">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-select
                size="small"
                v-model="scope.row.DateUnits"
                @change="onSelectChange($event, scope.$index)"
                placeholder=""
              >
                <el-option
                  v-for="item in cardSettingList.limitUnitsList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-show="!editMode">
              {{
                scope.row.DateUnits
                  | valueAndNameChange(cardSettingList.limitUnitsList)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-input
                v-model="scope.row.Note"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-show="!editMode">{{ scope.row.Note || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" class="button-box">
          <template slot-scope="scope">
            <!-- 移除未保存行 -->
            <el-button
              v-show="!scope.row.ScpiGuid"
              type="danger"
              size="mini"
              @click="removeTableData(scope.$index)"
            >
              移除
            </el-button>

            <!-- 移除已保存行 -->
            <el-button
              v-show="scope.row.ScpiGuid"
              type="danger"
              size="mini"
              :disabled="deleteDisable"
              @click="onDeletePackItem(scope.row.ScpiGuid)"
            >
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- end 套卡项目table -->

      <!-- start 赠送项目table -->
      <el-table
        v-show="type == 'dona_item'"
        v-loading="loading"
        :data="tableData"
        height="600"
      >
        <el-table-column label="编号" prop="ItemNo"> </el-table-column>
        <el-table-column label="项目名称" prop="ItemName"> </el-table-column>
        <el-table-column label="单价" prop="ItemPrice"> </el-table-column>
        <el-table-column label="组别">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-select
                size="small"
                v-model="scope.row.UniteIndex"
                @change="onSelectChange($event, scope.$index)"
                placeholder=""
              >
                <el-option
                  v-for="item in cardSettingList.uniteIndexList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-show="!editMode">
              {{
                scope.row.UniteIndex
                  | valueAndNameChange(cardSettingList.uniteIndexList)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="可用次数">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-input
                v-model="scope.row.DonaCount"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-show="!editMode">{{ scope.row.DonaCount || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="不限次数">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-select
                size="small"
                v-model="scope.row.InfiniteMark"
                @change="onSelectChange($event, scope.$index)"
                placeholder=""
              >
                <el-option
                  v-for="item in cardSettingList.booleanList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-show="!editMode">
              {{
                scope.row.InfiniteMark
                  | valueAndNameChange(cardSettingList.booleanList)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="消耗额">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-input
                v-model="scope.row.Consume"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-show="!editMode">{{ scope.row.Consume || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="有效期限">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-input
                v-model="scope.row.DateLimit"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-show="!editMode">{{ scope.row.DateLimit || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="期限单位">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-select
                size="small"
                v-model="scope.row.DateUnits"
                @change="onSelectChange($event, scope.$index)"
                placeholder=""
              >
                <el-option
                  v-for="item in cardSettingList.limitUnitsList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-show="!editMode">
              {{
                scope.row.DateUnits
                  | valueAndNameChange(cardSettingList.limitUnitsList)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <div v-show="editMode">
              <el-input
                v-model="scope.row.Note"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-show="!editMode">{{ scope.row.Note || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" class="button-box">
          <template slot-scope="scope">
            <el-button
              v-show="!scope.row.ScdiGuid"
              type="danger"
              size="mini"
              @click="removeTableData(scope.$index)"
            >
              移除
            </el-button>

            <el-button
              v-show="scope.row.ScdiGuid"
              type="danger"
              size="mini"
              :disabled="deleteDisable"
              @click="onDeletePackItem(scope.row.ScdiGuid)"
            >
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- end 赠送项目table -->

      <!-- start 腿部 -->
      <div class="setting-footer">
        <el-button size="mini" @click="onCloseDialog"> 取 消 </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="submitDisable"
          @click="onSubmitFrom(false)"
        >
          确 定
        </el-button>
      </div>
      <!-- end 腿部 -->
    </div>

    <DFSelectItem
      class="selectItem"
      ref="dfSelectItem"
      @onItemSelected="onItemSelected"
    >
    </DFSelectItem>
  </div>
</template>

<script>
import card from "@/api/card";
import utils from "@/utils/utils";
export default {
  props: {
    // 对话框区分
    type: {
      type: String,
      default: () => "",
    },

    // 所有下拉框的所有数据列表
    cardSettingList: {
      type: Object,
      default: () => ({}),
    },

    // 会员卡信息
    cardInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cardGuid: "", // 卡类型id
      tableData: [], // 表格数据列表
      packItemEditFromList: {
        // 添加、修改提交表格
        CardGuid: "",
        EditList: [],
      },
      editMode: false, // 修改模式
      submitDisable: false, // 提交按钮禁用
      deleteDisable: false, // 移除按钮禁用
      devideDisable: false, // 平分消耗额禁用
      loading: true, // 表格加载
    };
  },

  filters: {
    // 通用过滤器
    valueAndNameChange(item, sourceList, keyName, valueName) {
      return utils.valueAndNameChange(item, sourceList, keyName, valueName);
    },
  },

  mounted() {},

  methods: {
    // 初始化数据
    async initPageData(cardGuid) {
      this.cardGuid = cardGuid || this.cardGuid;
      this.loading = true;
      let submitData = {
        card_guid: this.cardGuid,
      };

      let { data } =
        this.type == "pack_item"
          ? await card.getPackItemList(submitData)
          : await card.getDonaItemList(submitData);
      this.tableData = data;
      this.loading = false;
    },

    // 保存提交接口
    async savePackItem() {
      this.submitDisable = await true;
      try {
        let { errcode } =
          this.type == "pack_item"
            ? await card.savePackItem(this.packItemEditFromList)
            : await card.saveDonaItem(this.packItemEditFromList);

        if (errcode == 0) {
          this.$message.success("保存成功");
        }
        this.packItemEditFromList = {
          CardGuid: "",
          EditList: [],
        };
        this.initPageData();
        Promise.resolve();
      } catch (err) {
        Promise.reject(err);
      } finally {
        this.resetAllPackData();
        this.submitDisable = false;
      }
    },

    // 输入框内容变化事件
    onInputChange(index) {
      this.tableData[index].Modified = 1;
    },

    // 下拉框内容变化事件
    onSelectChange(event, index) {
      this.tableData[index].Modified = 1;
    },

    // 移除一行
    removeTableData(index) {
      this.tableData.splice(index, 1);
    },

    // 关闭对话框事件
    onCloseDialog() {
      this.resetAllPackData();
      this.$emit("close");
    },

    // 重置表格
    resetAllPackData() {
      this.packItemEditFromList = {
        CardGuid: "",
        EditList: [],
      };
      this.editMode = false;
    },

    // 不同组别的消耗额算法
    async devideConsumption() {
      let param = {
        sum0: 0,
        count0: 0,
        sum1: 0,
        count1: 0,
        sum2: 0,
        count2: 0,
        sum3: 0,
        count3: 0,
      };
      let err = false;

      this.devideDisable = true;

      for (let i = 0; i < this.tableData.length; i++) {
        if (Number(this.tableData[i].UniteIndex) == 0) {
          param.count0 = param.count0 + Number(this.tableData[i].PackCount);
        } else {
          param["count" + this.tableData[i].UniteIndex] ==
          Number(this.tableData[i].PackCount)
            ? null
            : (param["sum" + this.tableData[i].UniteIndex] += 1);

          param["count" + this.tableData[i].UniteIndex] = Number(
            this.tableData[i].PackCount
          );
        }

        if (param["sum" + this.tableData[i].UniteIndex] > 1) {
          let group =
            this.tableData[i].UniteIndex == 3
              ? "组三"
              : this.tableData[i].UniteIndex == 2
              ? "组二"
              : this.tableData[i].UniteIndex == 1
              ? "组一"
              : "当前";
          err = true;
          this.$message.warning(
            `${group} 可用次数不一致，平分消耗额计算存在误差，请让 ${group} 所有可用次数保持一致`
          );
          break;
        }
      }

      if (!err) {
        // console.log(param.count0, param.count1, param.count2, param.count3);
        let consume = parseFloat(
          this.cardInfo.CostPrice /
            (param.count0 + param.count1 + param.count2 + param.count3)
        ).toFixed(2);
        // console.log(consume);

        await this.tableData.forEach((item, index) => {
          this.tableData[index].Consume = consume;
          this.tableData[index].Modified = 1;
        });
        await this.onSubmitFrom(true);
      }

      this.devideDisable = false;
    },

    // 移除当前列数据事件
    onDeletePackItem(id) {
      this.$confirm(`是否删除该项目`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let data = {};
        this.deleteDisable = true;

        if (this.type == "pack_item") {
          let submitData = { scpi_guid: id };
          data = await card.deletePackItem(submitData);
        } else {
          let submitData = { scdi_guid: id };
          data = await card.deleteDonaItem(submitData);
        }

        let { errcode, errmsg } = data;
        await this.$nextTick(() => {
          if (errcode == 0) {
            this.$message.success("项目删除成功");
            this.resetAllPackData();
            this.initPageData();
          } else {
            this.$message.error(errmsg);
          }
          this.deleteDisable = false;
        });
      });
    },

    // 新增项目、修改项目提交事件
    onSubmitFrom(isPassCheck = false) {
      // console.log(isPassCheck);
      let params = this.tableData;
      params.filter(async (item) => {
        if (item.Modified) {
          let param = {
            ItemGuid: item.ItemGuid,
            UniteIndex: Number(item.UniteIndex),
            Consume: item.Consume,
            DateLimit: Number(item.DateLimit),
            DateUnits: Number(item.DateUnits),
            Note: item.Note,
          };

          if (this.type == "pack_item") {
            param.ScpiGuid = item.ScpiGuid;
            param.PackCount = item.PackCount;
          } else {
            param.ScdiGuid = item.ScdiGuid;
            param.DonaCount = Number(item.DonaCount);
          }

          this.packItemEditFromList.EditList.push(param);
        }
      });

      this.packItemEditFromList.CardGuid = this.cardGuid;
      let modifiedCount = this.packItemEditFromList.EditList.length;
      this.$nextTick(() => {
        if (isPassCheck) {
          this.savePackItem();
        } else if (modifiedCount) {
          this.$confirm(
            `当前已有 ${modifiedCount} 行被修改，您确定将其保存吗？`,
            "保存提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(async () => {
              await this.savePackItem();
            })
            .finally(() => {
              this.packItemEditFromList = {
                CardGuid: "",
                EditList: [],
              };
              this.submitDisable = false;
            });
        } else {
          this.onCloseDialog();
        }
      });
    },

    // 打开选择项目对话框
    openSelectItemDialog() {
      let arr = [];
      this.tableData.forEach((item) => {
        arr.push(item.ItemGuid);
      });
      this.$refs.dfSelectItem.openSelectItemDialog(arr);
    },

    // 选择项目已选回调事件
    onItemSelected(event) {
      event = {
        ...event,
        Consume: 0,
        DateLimit: 0,
        DateUnits: 0,
        HeadMark: 0,
        InfiniteMark: 0,
        ItemPrice: event.Price,
        Modified: 1,
        Note: "",
        StoresGuid: "",
        UniteIndex: 0,
        ValidDateText: "",
      };

      if (this.type == "pack_item") {
        event.PackCount = 0;
        event.ScpiGuid = "";
      } else {
        event.DonaCount = 0;
        event.ScdiGuid = "";
      }

      this.tableData.push(event);
      // console.log(this.tableData);
    },
  },
};
</script>

<style lang="less" scoped>
.pack-item-body {
  .pack-top {
    margin-bottom: 20px;

    .seach-box {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      > .el-select {
        width: 170px;
      }

      > .el-input {
        width: 200px;
      }

      > * {
        margin: 0 5px;
      }
    }
  }

  .el-table {
    ::v-deep .el-button {
      margin-left: 10px !important;
    }
  }

  > .el-pagination {
    margin-top: 20px;
  }

  .setting-footer {
    position: absolute;
    // display: flex;
    // justify-content: flex-end;
    // align-items: flex-end;
    bottom: 0;
    right: 20px;
    padding: 20px 0;
  }
}
</style>