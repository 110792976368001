<template>
  <div class="setting-dialog">
    <!-- start 设置卡类型对话框 -->
    <el-dialog
      title="卡类型基础设置"
      :visible.sync="showSettingDialog"
      top="3%"
      width="70%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeSettingDialog"
    >
      <el-container class="setting-dialog-box">
        <!-- 侧边卡类型项目栏菜单 -->
        <el-aside class="left" width="200px">
          <el-menu
            :default-active="asideMenuIndex"
            class="el-menu-vertical-demo"
            @select="onAsideMenuSelect"
          >
            <el-menu-item
              v-for="(item, index) in menuList"
              :key="index"
              :index="item.type"
            >
              <span>{{ item.name }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>

        <!-- 主布局 -->
        <el-container class="right">
          <!-- start 折扣设置、赠送金额等独立组件 -->
          <el-main>
            <DiscTable
              v-if="asideMenuIndex == 'disc'"
              ref="discTable"
              :cardSettingList="cardSettingList"
              @close="closeSettingDialog"
            ></DiscTable>

            <PackItemTable
              v-else-if="asideMenuIndex == 'pack_item'"
              ref="packItemTable"
              :type="asideMenuIndex"
              :cardSettingList="cardSettingList"
              :cardInfo="cardInfo"
              @close="closeSettingDialog"
            ></PackItemTable>

            <PackItemTable
              v-else-if="asideMenuIndex == 'dona_item'"
              ref="packItemTable"
              :type="asideMenuIndex"
              :cardSettingList="cardSettingList"
              :cardInfo="cardInfo"
              @close="closeSettingDialog"
            ></PackItemTable>

            <DonaMoneyTable
              v-else-if="asideMenuIndex == 'dona_money'"
              ref="donaMoneyTable"
              :cardSettingList="cardSettingList"
              @close="closeSettingDialog"
            ></DonaMoneyTable>

            <InteTable
              v-else-if="asideMenuIndex == 'inte'"
              ref="inteTable"
              :cardSettingList="cardSettingList"
              @close="closeSettingDialog"
            ></InteTable>

            <PerforRoyaTable
              v-else-if="asideMenuIndex == 'perfor_roya'"
              ref="perforRoyaTable"
              :cardSettingList="cardSettingList"
              @close="closeSettingDialog"
            >
            </PerforRoyaTable>

            <AppoTable
              v-else-if="asideMenuIndex == 'appo'"
              ref="appoTable"
              :cardSettingList="cardSettingList"
              @close="closeSettingDialog"
            ></AppoTable>
          </el-main>
          <!-- end 折扣设置、赠送金额等独立组件 -->
        </el-container>
      </el-container>
    </el-dialog>
    <!-- end 设置卡类型对话框 -->
  </div>
</template>

<script>
import DiscTable from "./disc-table.vue";
import DonaMoneyTable from "./dona-money-table.vue";
import PackItemTable from "./pack-item-table.vue";
import PerforRoyaTable from "./perfor-roya-table.vue";
import InteTable from "./inte-table.vue";
import AppoTable from "./appo-table.vue";
export default {
  components: {
    DiscTable,
    DonaMoneyTable,
    PackItemTable,
    PerforRoyaTable,
    InteTable,
    AppoTable,
  },

  watch: {},

  props: {
    // 会员卡id
    cardGuid: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      cardInfo: "", // 会员卡信息
      cardSettingList: {}, // 所有下拉框的数据列表
      menuList: [], // 侧边menu菜单数据列表
      asideMenuIndex: "", // 当前侧边菜单选中项
      refsName: "", // 当前组件虚拟dom的名称
      showSettingDialog: false, // 对话框的显示
    };
  },

  mounted() {},

  methods: {
    // 侧边菜单切换事件
    onAsideMenuSelect(key) {
      this.asideMenuIndex = key;
      this.$nextTick(() => {
        this.refsName = this.menuList.filter((item) => item.type == key)[0].ref;

        this.$refs[this.refsName].initPageData(this.cardInfo.CardGuid);
      });
    },

    // 初始化menu菜单栏数据列表
    initMenuList() {
      let arr = [
        {
          id: 3,
          name: "指定项目",
          ref: "appoTable",
          type: "appo",
        },
        {
          id: 1,
          name: "折扣设置",
          ref: "discTable",
          type: "disc",
        },
        {
          id: 2,
          name: "套卡项目",
          ref: "packItemTable",
          type: "pack_item",
        },
        {
          id: 4,
          name: "赠送金额",
          ref: "donaMoneyTable",
          type: "dona_money",
        },
        {
          id: 5,
          name: "赠送项目",
          ref: "packItemTable",
          type: "dona_item",
        },
        {
          id: 6,
          name: "积分设置",
          ref: "inteTable",
          type: "inte",
        },
        {
          id: 7,
          name: "提成设置",
          ref: "perforRoyaTable",
          type: "perfor_roya",
        },
      ];

      let { cardInfo } = this.cardSettingList;

      let param = arr.filter((item) => {
        // 应该有更好的优化方案，目前的条件判断个人认为还不够优雅，但是暂时先这么写
        let show = true;
        if (item.type == "dona_item") {
          if (cardInfo.DonaItem == 0) {
            show = false;
          }
        } else if (item.type == "pack_item") {
          if (cardInfo.NatureNo != "0002" || cardInfo.NullPack == 1) {
            show = false;
          }
        } else if (item.type == "disc" || item.type == "dona_money") {
          if (cardInfo.NatureNo == "0002") {
            show = false;
          }
        } else if (item.type == "appo") {
          if (cardInfo.NatureNo != "0003") {
            show = false;
          }
        }

        if (show) {
          return item;
        }
      });

      this.menuList = param;
    },

    // 打开对话框
    async openSettingDialog(cardSettingList, cardInfo) {
      this.cardSettingList = cardSettingList;
      this.cardInfo = cardInfo;

      this.initMenuList();
      this.$nextTick(() => {
        // 每次打开对话框asideMenuIndex需要重新赋新值，但是对话框没渲染完，这个段代码就跑完了，导致el-menu异常需要nextTick
        this.onAsideMenuSelect(this.menuList[0].type);
      });

      this.showSettingDialog = true;
    },

    // 关闭对话框
    closeSettingDialog() {
      this.asideMenuIndex = "";
      this.menuList = "";
      this.showSettingDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.setting-dialog {
  ::v-deep .el-dialog {
    height: 780px !important;

    .el-dialog__body {
      padding: 0 !important;
    }

    .setting-dialog-box {
      height: 720px;
      // border: 1px solid black;

      .left {
        width: 150px !important;
        height: 100%;
        // padding: 0 20px;
        border-right: 1px solid #e6e6e6;

        .el-menu {
          width: 100%;
          height: 100%;
          border: 0;
          text-align: center;
          // border: 1px solid black;
        }
      }

      .right {
        .el-main {
          margin: 0;
          padding: 0 20px;
        }
      }

      // .el-footer {
      //   height: 30px !important;
      //   display: flex;
      //   justify-content: flex-end;
      // }
    }
  }
}
</style>