<template>
  <div class="perfor-roya-body">
    <!-- start 顶部tab栏 -->
    <div class="perfor-top">
      <el-select
        size="small"
        v-model="dutyNo"
        @change="onDutyChange"
        placeholder="请选择职位"
      >
        <el-option
          v-for="item in cardSettingList.dutyList"
          :key="item.DutyNo"
          :label="item.DutyName"
          :value="item.DutyNo"
        >
        </el-option>
      </el-select>

      <el-select
        size="small"
        v-model="levelGuid"
        @change="onlevelChange"
        placeholder=""
      >
        <el-option
          v-for="item in levelList"
          :key="item.LevelGuid"
          :label="item.LevelName"
          :value="item.LevelGuid"
        >
        </el-option>
      </el-select>

      <div class="edit-box">
        <!-- 新增一行按钮 -->
        <el-button v-show="editMode" size="small" @click="addNewTableData"
          >添加新行</el-button
        >

        <!-- 编辑模式按钮 -->
        <el-button type="primary" size="small " @click="editMode = !editMode">{{
          editMode ? "关闭编辑" : "开启编辑"
        }}</el-button>
      </div>
    </div>
    <!-- end 顶部tab栏 -->

    <!-- start 提示文本 -->
    <div class="tips">
      如多职位同时分享提成，而每个职位的可提也不一样，需在第一条规则上设为不限，并且设置可提为所有职位共同可提的最大值
    </div>
    <!-- end 提示文本 -->

    <!-- start 赠送金额table -->
    <el-table v-loading="loading" :data="tableData" height="580">
      <el-table-column label="员工职位" width="100">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.DutyNo | dutyNoAndNameChange(cardSettingList.dutyList)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="职位级别" width="100">
        <template slot-scope="scope">
          {{
            scope.row.LevelGuid
              | levelGuidAndNameChange(cardSettingList.levelList)
          }}
        </template>
      </el-table-column>
      <el-table-column label="开卡/充值" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-select
              size="small"
              v-model="scope.row.KeepType"
              @change="onSelectChange($event, scope.$index)"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.keepTypeList"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-show="!editMode">
            {{
              scope.row.KeepType
                | valueAndNameChange(cardSettingList.keepTypeList)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="开始金额" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.KeepStart"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.KeepStart || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="结束金额" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.KeepEnd"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.KeepEnd || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="业绩模式" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-select
              size="small"
              v-model="scope.row.PerforType"
              @change="onSelectChange($event, scope.$index)"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.discMode"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-show="!editMode">
            {{
              scope.row.PerforType
                | valueAndNameChange(cardSettingList.discMode)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="按金额" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.PerforMoney"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.PerforMoney || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="按比例" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.PerforRatio"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.PerforRatio || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="工本模式" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-select
              size="small"
              v-model="scope.row.CostType"
              @change="onSelectChange($event, scope.$index)"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.discMode"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-show="!editMode">
            {{
              scope.row.CostType | valueAndNameChange(cardSettingList.discMode)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="按金额" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.CostMoney"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.CostMoney || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="按比例" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.CostRatio"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.CostRatio || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="提成模式" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-select
              size="small"
              v-model="scope.row.RoyaType"
              @change="onSelectChange($event, scope.$index)"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.discMode"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-show="!editMode">
            {{
              scope.row.RoyaType | valueAndNameChange(cardSettingList.discMode)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="按金额" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.RoyaMoney"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.RoyaMoney || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="按比例" width="100">
        <template slot-scope="scope">
          <div v-show="editMode">
            <el-input
              v-model="scope.row.RoyaRatio"
              @change="onInputChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-show="!editMode">{{ scope.row.RoyaRatio || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" class="button-box" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-show="!scope.row.ScprGuid"
            type="danger"
            size="mini"
            @click="removeTableData(scope.$index)"
          >
            移除
          </el-button>

          <el-button
            v-show="scope.row.ScprGuid"
            :disabled="deleteDisable"
            type="danger"
            size="mini"
            @click="onDeleteDiscItem(scope.row.ScprGuid)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 赠送金额table -->

    <!-- start 腿部 -->
    <div class="setting-footer">
      <el-button size="mini" @click="onCloseDialog"> 取 消 </el-button>
      <el-button
        size="mini"
        type="primary"
        :disabled="submitDisable"
        @click="onSubmitFrom"
      >
        确 定
      </el-button>
    </div>
    <!-- end 腿部 -->
  </div>
</template>

<script>
import card from "@/api/card";
import utils from "@/utils/utils";
export default {
  props: {
    // 所有下拉框的所有数据列表
    cardSettingList: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cardGuid: "", // 卡类型id
      dutyNo: " ",
      levelGuid: " ",
      levelList: [],
      perforRoyaList: [],
      tableData: [], // 表格数据列表
      perforRoyaFromList: {
        // 添加、修改提交表格
        CardGuid: "",
        EditList: [],
      },
      editMode: false, // 修改模式
      deleteDisable: false, // 删除按钮禁用
      submitDisable: false, // 提交按钮禁用
      loading: false, // 表格加载
    };
  },

  filters: {
    // 通用过滤器
    valueAndNameChange(item, sourceList, keyName, valueName) {
      return utils.valueAndNameChange(item, sourceList, keyName, valueName);
    },

    dutyNoAndNameChange(item, sourceList) {
      return sourceList.filter((event) => event.DutyNo == item)[0].DutyName;
    },

    levelGuidAndNameChange(item, sourceList) {
      return item == "" || item == " "
        ? ""
        : sourceList.filter((event) => event.LevelGuid == item)[0].LevelName;
    },
  },

  mounted() {
    // console.log(this.cardSettingList);
  },

  methods: {
    // 初始化数据
    async initPageData(cardGuid, levelGuid) {
      this.cardGuid = cardGuid || this.cardGuid;
      let submitData = {
        card_guid: this.cardGuid,
        duty_no: this.dutyNo,
        level_guid: this.levelGuid,
      };

      let { data } = await card.getPerforRoyaList(submitData);

      this.perforRoyaList = data;
      this.levelList.splice(0, 1);
      this.tableDataChange(levelGuid);
      // console.log(data);
    },

    // 职位改变事件
    onDutyChange(event) {
      let arr = this.cardSettingList.levelList.filter((item) => {
        return item.DutyNo == event;
      });
      this.levelList = arr;
      this.initPageData();
    },

    // 等级改变事件
    onlevelChange(event) {
      this.initPageData(this.cardGuid, event);
    },

    // 表格数据更改事件
    tableDataChange(event = "") {
      this.loading = true;
      if (event) {
        this.tableData = this.perforRoyaList.filter((item) => {
          return item.DutyNo == this.dutyNo && item.LevelGuid == this.levelGuid;
        });
      } else {
        this.tableData = this.perforRoyaList.filter((item) => {
          return item.DutyNo == this.dutyNo;
        });
      }
      this.levelList.unshift({
        DutyNo: " ",
        LevelGuid: " ",
        LevelName: "不限",
      });
      // console.log(this.perforRoyaList, this.tableData);
      this.loading = false;
    },

    // 输入框内容变化事件
    onInputChange(index) {
      this.tableData[index].Modified = 1;
    },

    // 下拉框内容变化事件
    onSelectChange(event, index) {
      this.tableData[index].Modified = 1;
    },

    // 新增一行
    addNewTableData(index) {
      this.tableData.push({
        CardGuid: "",
        CostMoney: 0,
        CostRatio: 0,
        CostType: 0,
        DutyNo: this.dutyNo,
        HeadMark: 0,
        KeepEnd: 0,
        KeepStart: 0,
        KeepType: 0,
        LevelGuid: this.levelGuid,
        Modified: 0,
        PerforMoney: 0,
        PerforRatio: 0,
        PerforType: 0,
        RoyaMoney: 0,
        RoyaRatio: 0,
        RoyaType: 0,
        ScprGuid: "",
        StoresGuid: "",
      });
    },

    // 移除一行
    removeTableData(index) {
      this.tableData.splice(index, 1);
    },

    // 关闭对话框事件
    onCloseDialog() {
      this.resetAllDiscData();
      this.$emit("close");
    },

    // 重置表格
    resetAllDiscData() {
      this.perforRoyaFromList = {
        CardGuid: "",
        EditList: [],
      };
      this.editMode = false;
    },

    // 移除当前列数据事件
    onDeleteDiscItem(id) {
      this.$confirm(`是否删除该项目`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let submitData = { scpr_guid: id };

        this.deleteDisable = true;

        let { errcode, errmsg } = await card.deletePerforRoya(submitData);

        await this.$nextTick(() => {
          if (errcode == 0) {
            this.$message.success("项目删除成功");
            this.resetAllDiscData();
            this.initPageData(this.cardGuid, this.levelGuid);
          } else {
            this.$message.error(errmsg);
          }
          this.deleteDisable = false;
        });
      });
    },

    // 新增项目、修改项目提交事件
    onSubmitFrom() {
      let params = this.tableData;
      params.filter(async (item) => {
        if (item.Modified) {
          this.perforRoyaFromList.EditList.push({
            ScprGuid: item.ScprGuid,
            DutyNo: item.DutyNo,
            LevelGuid: item.LevelGuid,
            KeepType: item.KeepType,
            KeepStart: parseFloat(item.KeepStart).toFixed(2) * 1,
            KeepEnd: parseFloat(item.KeepEnd).toFixed(2) * 1,
            PerforType: item.PerforType,
            PerforMoney: parseFloat(item.PerforMoney).toFixed(2) * 1,
            PerforRatio: parseInt(item.PerforRatio),
            CostType: item.CostType,
            CostMoney: parseFloat(item.CostMoney).toFixed(2) * 1,
            CostRatio: parseInt(item.CostRatio),
            RoyaType: item.RoyaType,
            RoyaMoney: parseFloat(item.RoyaMoney).toFixed(2) * 1,
            RoyaRatio: parseInt(item.RoyaRatio),
          });
        }
      });
      let modifiedCount = this.perforRoyaFromList.EditList.length;
      // console.log(this.perforRoyaFromList.EditList);
      if (modifiedCount) {
        this.$confirm(
          `当前已有 ${modifiedCount} 行被修改，您确定将其保存吗？`,
          "保存提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            this.submitDisable = true;

            this.perforRoyaFromList.CardGuid = this.cardGuid;
            try {
              let { errcode } = await card.savePerforRoya(
                this.perforRoyaFromList
              );

              if (errcode == 0) {
                this.$message.success("保存成功");
                this.initPageData(this.cardGuid, this.levelGuid);
              }
            } finally {
              this.submitDisable = false;
            }
          })
          .finally(() => {
            this.perforRoyaFromList = {
              CardGuid: "",
              EditList: [],
            };
            this.submitDisable = false;
          });
      } else {
        this.onCloseDialog();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.perfor-roya-body {
  .perfor-top {
    margin-bottom: 20px;
    position: relative;

    > .el-select {
      width: 20%;
    }

    > .el-input {
      width: 200px;
    }

    > * {
      margin-right: 10px;
    }

    .edit-box {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .tips {
    color: tomato;
  }

  .el-table {
    ::v-deep .el-button {
      margin-left: 10px !important;
    }
  }

  > .el-pagination {
    margin-top: 20px;
  }

  .setting-footer {
    position: absolute;
    // display: flex;
    // justify-content: flex-end;
    // align-items: flex-end;
    bottom: 0;
    right: 20px;
    padding: 20px 0;
  }
}
</style>