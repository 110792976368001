<template>
  <div class="disc-body">
    <!-- start 顶部tab栏 -->
    <div class="disc-top">
      <!-- 单选按钮 -->
      <el-radio-group
        size="small"
        v-model="discType"
        @change="onDiscTypeChange"
      >
        <el-radio-button
          v-for="item in discTypeList"
          :key="item.id"
          :label="item.value"
          >{{ item.title }}</el-radio-button
        >
      </el-radio-group>

      <div class="seach-box">
        <!-- 已设置的项目 -->
        <el-checkbox
          v-show="discType == 'disc_item'"
          v-model="checked"
          @change="onSelectDiscItem"
          >已设置的项目</el-checkbox
        >

        <!-- 分类下拉框 -->
        <el-select
          v-show="discType == 'disc_item'"
          v-model="selectClassGuid"
          size="small "
          placeholder="全部"
        >
          <el-option
            v-for="item in cardSettingList.itemClassList"
            :key="item.ClassGuid"
            :label="item.ClassName"
            :value="item.ClassGuid"
          >
          </el-option>
        </el-select>

        <!-- <div class="seach-input"> -->
        <!-- 搜索输入框 -->
        <el-input
          v-show="discType == 'disc_item'"
          v-model="seachText"
          size="small "
          placeholder="搜索项目编号或项目名称"
        ></el-input>

        <!-- 查找按钮 -->
        <el-button
          v-show="discType == 'disc_item'"
          type="primary"
          size="small "
          @click="onSeachDiscItem"
          >查找</el-button
        >
        <!-- </div> -->

        <!-- 编辑模式按钮 -->
        <el-button
          class="editButton"
          type="primary"
          size="small "
          @click="editMode = !editMode"
          >{{ editMode ? "关闭编辑" : "开启编辑" }}</el-button
        >
      </div>
    </div>
    <!-- end 顶部tab栏 -->

    <!-- start 项目分类设置的table模板 -->
    <el-table
      v-show="discType == 'disc_class'"
      v-loading="loading"
      :data="tableData"
      height="560"
    >
      <el-table-column
        prop="ClassNo"
        label="分类编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="ClassName"
        label="分类名称"
        width="180"
      ></el-table-column>
      <el-table-column label="折扣模式">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-select
              size="small"
              v-model="scope.row.ClassDiscModel"
              @change="onDiscModeChange($event, scope.$index, 'ClassDiscModel')"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.discMode"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else>
            {{
              scope.row.ClassDiscModel
                | valueAndNameChange(cardSettingList.discMode)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="按金额">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-input
              v-model="scope.row.ClassDiscMoney"
              @change="onDiscMoneyChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-else>{{ scope.row.ClassDiscMoney || "不打折" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="按比例">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-select
              size="small"
              v-model="scope.row.ClassDiscGuid"
              @change="onDiscGuidChange($event, scope.$index, 'ClassDiscName')"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.discPreset"
                :key="item.DiscGuid"
                :label="item.DiscName"
                :value="item.DiscGuid"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else>{{ scope.row.ClassDiscName || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            ref="deleteButton"
            type="danger"
            size="mini"
            :disabled="!scope.row.ScdcGuid || deleteDisable"
            @click="onDeleteDiscItem(scope.row.ScdcGuid)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 项目分类设置的table模板  -->

    <!-- start 项目特别设置的table模板 -->
    <el-table
      v-show="discType == 'disc_item'"
      v-loading="loading"
      :data="tableData"
      height="540"
    >
      <el-table-column
        prop="ClassName"
        label="项目分类"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="ItemNo"
        label="项目编号"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="ItemName"
        label="项目名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="ItemPrice"
        label="项目单价"
        width="80"
      ></el-table-column>
      <el-table-column label="折扣模式">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-select
              size="small"
              v-model="scope.row.ItemDiscModel"
              @change="onDiscModeChange($event, scope.$index, 'ItemDiscModel')"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.discMode"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else>
            {{
              scope.row.ItemDiscModel
                | valueAndNameChange(cardSettingList.discMode)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="按金额(元)">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-input
              v-model="scope.row.ItemDiscMoney"
              @change="onDiscMoneyChange(scope.$index)"
              size="small"
              v-focus
              placeholder=""
            />
          </div>
          <div v-else>{{ scope.row.ItemDiscMoney || "不打折" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="按比例(折)">
        <template slot-scope="scope">
          <div v-if="editMode">
            <el-select
              size="small"
              v-model="scope.row.ItemDiscGuid"
              @change="onDiscGuidChange($event, scope.$index, 'ItemDiscName')"
              placeholder=""
            >
              <el-option
                v-for="item in cardSettingList.discPreset"
                :key="item.DiscGuid"
                :label="item.DiscName"
                :value="item.DiscGuid"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else>{{ scope.row.ItemDiscName || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            :disabled="!scope.row.ScdiGuid || deleteDisable"
            @click="onDeleteDiscItem(scope.row.ScdiGuid)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 项目特别设置的table模板 -->

    <!-- start 分页器 -->
    <el-pagination
      v-show="discType != 'disc_class'"
      background
      :page-sizes="[12, 20, 25]"
      :page-size="pageSize"
      :current-page="pageIndex"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <!-- start 腿部 -->
    <div class="disc-footer">
      <el-button size="mini" @click="onCloseDialog"> 取 消 </el-button>
      <el-button
        size="mini"
        type="primary"
        :disabled="submitDisable"
        @click="onSubmitFrom"
      >
        确 定
      </el-button>
    </div>
    <!-- end 腿部 -->
  </div>
</template>

<script>
import card from "@/api/card";
import utils from "@/utils/utils";
export default {
  props: {
    // 所有下拉框的数据列表
    cardSettingList: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cardGuid: "", // 卡类型id
      selectClassGuid: "", // 项目分类id
      seachText: "", // 搜索栏关键词文本
      discType: "disc_class", // 当前项目设置类型
      discTypeList: [
        // 顶部单选按钮的options列表
        { id: 1, title: "按项目分类设置", value: "disc_class" },
        { id: 2, title: "对某项目特别设置", value: "disc_item" },
      ],
      tableData: [], // 表格数据列表
      copeTableData: [], // 表格数据拷贝列表
      discEditFromList: {
        // 添加、修改提交表格
        CardGuid: "",
        EditList: [],
      },
      pageIndex: 1, // 页码
      pageSize: 12, // 页数
      totalRecord: 0, // 总页数
      editMode: false, // 编辑模式
      deleteDisable: false, // 删除按钮禁用
      submitDisable: false, // 提交按钮禁用
      loading: true, // 表格加载
      checked: true, // 参与轮牌
    };
  },

  filters: {
    // 通用过滤器
    valueAndNameChange(item, sourceList, keyName, valueName) {
      return utils.valueAndNameChange(item, sourceList, keyName, valueName);
    },
  },

  mounted() {
    // console.log(this.cardGuid);
  },

  methods: {
    // 初始化数据
    async initPageData(cardGuid) {
      this.cardGuid = cardGuid || this.cardGuid;
      this.loading = true;

      try {
        if (this.discType == "disc_class") {
          let submitData = { card_guid: this.cardGuid };
          let { data } = await card.getDiscClassList(submitData);
          return (this.tableData = data);
        } else {
          let submitData = {
            card_guid: this.cardGuid,
            class_guid: this.selectClassGuid,
            word: this.seachText,
            set: this.checked ? 1 : 0,
          };
          let { data } = await card.getDiscItemList(submitData);

          // this.tableData = data;
          this.copeTableData = data;
          this.totalRecord = data.length;

          // this.checked && this.discType != "disc_class"
          //   ? this.onSelectDiscItem()
          //   : null;
          return this.falsePage();
        }
      } finally {
        this.loading = false;
      }
    },

    // 伪分页
    falsePage() {
      let start = (this.pageIndex - 1) * this.pageSize,
        end = this.pageIndex * this.pageSize;
      this.tableData = this.copeTableData.slice(start, end);
      // console.log(this.tableData);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.falsePage();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.recordModifiedTableData();
      this.falsePage();
    },

    // 记录被修改的表格数据
    recordModifiedTableData() {
      let params = this.tableData.filter((item) => {
        return item.Modified;
      });
      params.forEach((item2) => {
        this.copeTableData.filter((item3) => {
          item3.ItemGuid == item2.ItemGuid ? (item3 = item2) : null;
        });
      });
    },

    // 项目分类设置、项目特别设置menu切换
    onDiscTypeChange(event) {
      this.discType = event;
      this.discEditFromList = {
        CardGuid: "",
        EditList: [],
      };
      this.pageIndex = 1;
      this.pageSize = 12;
      this.initPageData();
    },

    // 已设置项目过滤
    onSelectDiscItem() {
      // this.checked
      //   ? ((this.copeTableData = this.tableData),
      //     (this.tableData = this.tableData.filter((item) => {
      //       return item.ScdiGuid;
      //     })))
      //   : (this.tableData = this.copeTableData);
      this.initPageData();
    },

    // 关键词、条件查询
    onSeachDiscItem() {
      this.pageIndex = 1;
      this.pageSize = 12;
      this.initPageData();
    },

    // 折扣模式变化事件
    onDiscModeChange(event, index, fieldName) {
      console.log();
      this.tableData[index].Modified = 1;
      this.tableData[index][fieldName] = event;
    },

    // 按金额变化事件-
    onDiscMoneyChange(index) {
      this.tableData[index].Modified = 1;
    },

    // 按比例变化事件
    onDiscGuidChange(event, index, fieldName) {
      // console.log(event, index, text);
      this.tableData[index].Modified = 1;
      this.tableData[index][fieldName] = this.cardSettingList.discPreset.filter(
        (item) => {
          return item.DiscGuid == event;
        }
      )[0].DiscName;
    },

    // 关闭对话框事件
    onCloseDialog() {
      this.resetAllDiscData();
      this.$emit("close");
    },

    // 重置表格
    resetAllDiscData() {
      this.discEditFromList = {
        CardGuid: "",
        EditList: [],
      };
      // this.selectClassGuid = "";
      // this.seachText = "";
      // this.discType = "disc_class";
      this.editMode = false;
    },

    // 移除当前列数据事件
    onDeleteDiscItem(id) {
      this.$confirm(`是否删除该项目`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let submitData =
          this.discType == "disc_class" ? { scdc_guid: id } : { scdi_guid: id };

        this.deleteDisable = true;

        let { errcode, errmsg } = await (this.discType == "disc_class"
          ? card.deleteDiscClass(submitData)
          : card.deleteDiscItem(submitData));

        await this.$nextTick(() => {
          if (errcode == 0) {
            this.$message.success("项目删除成功");
            this.initPageData();
          } else {
            this.$message.error(errmsg);
          }
          this.deleteDisable = false;
        });
      });
    },

    // 新增项目、修改项目提交事件
    onSubmitFrom() {
      let params =
        this.discType == "disc_class" ? this.tableData : this.copeTableData;
      params.filter(async (item) => {
        if (item.Modified) {
          this.discEditFromList.EditList.push(
            this.discType == "disc_class"
              ? {
                  ClassGuid: item.ClassGuid,
                  ScdcGuid: item.ScdcGuid,
                  ClassDiscModel: item.ClassDiscModel,
                  ClassDiscMoney: item.ClassDiscMoney || 0,
                  ClassDiscGuid: item.ClassDiscGuid,
                }
              : {
                  ItemGuid: item.ItemGuid,
                  ScdiGuid: item.ScdiGuid,
                  ItemDiscModel: item.ItemDiscModel,
                  ItemDiscMoney: item.ItemDiscMoney || 0,
                  ItemDiscGuid: item.ItemDiscGuid,
                }
          );
        }
      });
      let modifiedCount = this.discEditFromList.EditList.length;

      if (modifiedCount) {
        this.$confirm(
          `当前已有 ${modifiedCount} 行被修改，您确定将其保存吗？`,
          "保存提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            this.submitDisable = await true;

            this.discEditFromList.CardGuid = this.cardGuid;
            try {
              let { errcode } = await (this.discType == "disc_class"
                ? card.saveDiscClass(this.discEditFromList)
                : card.saveDiscItem(this.discEditFromList));

              if (errcode == 0) {
                this.$message.success("修改成功");
                this.initPageData();
              }
            } finally {
              this.submitDisable = false;
            }
          })
          .finally(() => {
            this.discEditFromList = {
              CardGuid: "",
              EditList: [],
            };
            this.submitDisable = false;
          });
      } else {
        this.onCloseDialog();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.disc-body {
  .disc-top {
    margin-bottom: 20px;

    .seach-box {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      > .editButton {
        position: absolute;
        right: 0;
      }

      > .el-select {
        width: 170px;
      }

      > .el-input {
        width: 200px !important;
      }

      > * {
        margin: 0 5px;
      }
    }
  }

  > .el-pagination {
    margin-top: 20px;
  }

  .disc-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    padding: 20px 0;
  }
}
</style>